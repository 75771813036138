import React, { Component } from 'react'
import logo from './RLE-logo.svg'
import './App.css'

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>The work of Ryne Estwing</h1>
          <p>More info coming soon</p>
          <a href="http://cargocollective.com/ryneestwing">View recent work on Cargo</a>
          <p className="copyright">
            &copy; {new Date().getFullYear()} Ryne Estwing
          </p>
        </header>
      </div>
    )
  }
}

export default App
